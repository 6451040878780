import type {ApolloError} from '@apollo/client';
import type {GraphQLFormattedError} from 'graphql';

export function isMetafieldDefinitionAccessDeniedError(
  error: GraphQLFormattedError,
) {
  return (
    error.extensions?.code === 'ACCESS_DENIED' &&
    error.path?.includes('metafieldDefinition')
  );
}

export function isErrorExpected(error: ApolloError) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors.every(isMetafieldDefinitionAccessDeniedError) &&
    !error.networkError
  );
}
